import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import type { RootState } from "../types"
import _ from "lodash"
import {
  PreApprovalFormEntity,
  PreApprovalQuestion,
  PreApprovalStep,
} from "features/preApprovalForms/types"
import qs from "qs"
import { GroupGroup, UserUser } from "@mobilemind/common/src/types/jsonapi"

export const getPreApprovalForms = createAsyncThunk<
  Array<Array<PreApprovalFormEntity>>,
  void,
  { state: RootState }
>(
  "preApprovalForms/getPreApprovalForms",
  async (args, thunkAPI): Promise<PreApprovalFormEntity[][]> => {
    const query = {
      filter: {
        "field_organization.id":
          thunkAPI.getState().session.group.uuid[0].value,
      },
      include: "field_questions",
    }

    const response = await fetchWrapper.get(
      "/api/mm_form/pre_approval?" + qs.stringify(query)
    )
    if (response.ok) {
      const data = await response.json()

      data.data.forEach((form: PreApprovalFormEntity) => {
        form.questions = data.included?.filter((included: any) => {
          return form.relationships?.field_questions?.data.find(
            (question: any) => question.id === included.id
          )
        })

        form.questions?.forEach((question: PreApprovalQuestion) => {
          if (question.type === "paragraph--fixed_choice_questions") {
            question.attributes.field_answer_op =
              // @ts-ignore
              question.attributes.field_answer_op.split(",")
          }
        })
      })

      return _.orderBy(data.data, "attributes.label", "asc")
    }
    return []
  }
)

export const getPreApprovalStepsForForm = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("preApprovalForms/getPreApprovalStepsForForm", async (args, thunkAPI) => {
  const { form } = args
  const formUUID = form.id

  const query = {
    filter: { "field_form.id": formUUID },
    include: "field_reviewer,field_reviewer_group",
  }

  const response = await fetchWrapper.get(
    "/api/mm_appr/mm_appr?" + qs.stringify(query)
  )

  if (response.ok) {
    const data = await response.json()

    data.data.forEach((step: PreApprovalStep) => {
      step.attributes.field_approver_instructions =
        // @ts-ignore
        step.attributes.field_approver_instructions?.value ?? ""

      const reviewerGroups = data.included?.filter(
        (inc: any) => inc.type === "group--rev_group"
      )

      const reviewerGroupsForStep = reviewerGroups?.filter(
        (group: GroupGroup) =>
          step.relationships?.field_reviewer_group?.data?.find(
            (g: any) => g.id === group.id
          )
      )

      const reviewers = data.included?.filter(
        (inc: any) => inc.type === "user--user"
      )

      const reviewersForStep = reviewers?.filter((reviewer: UserUser) =>
        step.relationships?.field_reviewer?.data?.find((r: any) => {
          return r.id === reviewer.id
        })
      )

      step.reviewers = reviewersForStep ?? []
      step.reviewerGroups = reviewerGroupsForStep ?? []
    })

    // Put the approval steps in the order they appear on the form entity's relationship
    const orderedSteps = form.relationships.field_approval_steps.data.map(
      (step: any) => {
        return data.data.find((s: PreApprovalStep) => s.id === step.id)
      }
    )

    return orderedSteps
  } else return []
})

export const savePreApprovalQuestions = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("preApprovalForms/savePreApprovalQuestions", async (args, thunkAPI) => {
  const { session } = thunkAPI.getState()
  const { values, formData } = args
  const { questions } = values

  const questionRelationship: {
    id: string
    type: string
    meta: {
      drupal_internal__target_id: number
      target_revision_id: number
    }
  }[] = []

  let i = 0
  while (i < questions.length) {
    const targetQuestion = questions[i]

    const { type } = targetQuestion
    const bundle = type.split("--")[1]

    // Build out our question body
    const questionBody: { data: PreApprovalQuestion } = {
      data: {
        type: targetQuestion.type,
        attributes: {
          field_question_name: targetQuestion.attributes.field_question_name,
          field_required: targetQuestion.attributes.field_required,
          parent_field_name: "field_questions",
          parent_id: formData.attributes.drupal_internal__id,
          parent_type: "mm_form",
        },
      },
    }

    if (bundle === "fixed_choice_questions") {
      if (targetQuestion.attributes.field_answer_op) {
        questionBody.data.attributes.field_answer_op =
          targetQuestion.attributes.field_answer_op.join(",")
      }

      if (targetQuestion.attributes.field_multi_select) {
        questionBody.data.attributes.field_multi_select =
          targetQuestion.attributes.field_multi_select
      }
    }

    let questionResponse
    // If the question has an ID we're patching, otherwise we're creating
    if (targetQuestion.id) {
      questionBody.data.id = targetQuestion.id

      questionResponse = await fetchWrapper.patch(
        "/api/paragraph/" + bundle + "/" + targetQuestion.id,
        session.token,
        JSON.stringify(questionBody)
      )
    } else {
      questionResponse = await fetchWrapper.post(
        "/api/paragraph/" + bundle,
        session.token,
        JSON.stringify(questionBody)
      )
    }

    if (questionResponse.ok) {
      const questionResponseData = await questionResponse.json()
      // Add in what we'll need for our relationship on the form
      questionRelationship.push({
        id: questionResponseData.data.id,
        type: questionResponseData.data.type,
        meta: {
          drupal_internal__target_id:
            questionResponseData.data.attributes.drupal_internal__id,
          target_revision_id:
            questionResponseData.data.attributes.drupal_internal__revision_id,
        },
      })
    }

    i++
  }

  return questionRelationship
})

export const deletePreApprovalQuestion = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("preApprovalForms/deletePreApprovalQuestion", async (args, thunkAPI) => {
  const { session } = thunkAPI.getState()
  const { values, questionId } = args
  const { questions } = values

  if (questionId) {
    const formId = values.id

    const remainingQuestions = questions.find(
      (question: PreApprovalQuestion) =>
        question.id && question.id !== questionId
    )

    const targetQuestion = questions.find(
      (question: PreApprovalQuestion) => question.id === questionId
    )

    const bundle = targetQuestion.type.split("--")[1]

    await fetchWrapper.remove(
      "/api/paragraph/" + bundle + "/" + targetQuestion.id,
      session.token
    )

    const formUpdateBody: { data: PreApprovalFormEntity } = {
      data: {
        type: "mm_form--pre_approval",
        id: formId,
        relationships: {
          field_questions: {
            data: remainingQuestions.map((question: PreApprovalQuestion) => {
              return {
                id: question.id,
                type: question.type,
                meta: {
                  drupal_internal__target_id:
                    // @ts-ignore
                    question.attributes.drupal_internal__id,
                  target_revision_id:
                    // @ts-ignore
                    question.attributes.drupal_internal__revision_id,
                },
              }
            }),
          },
        },
      },
    }

    await fetchWrapper.patch(
      "/api/mm_form/pre_approval/" + formId,
      session.token,
      JSON.stringify(formUpdateBody)
    )
  }

  return true
})

export const savePreApprovalSteps = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("preApprovalForms/savePreApprovalSteps", async (args, thunkAPI) => {
  const { session } = thunkAPI.getState()
  const { values, formData } = args
  const { approvalSteps } = values

  const approvalStepsRelationship: {
    id: string
    type: "mm_appr--mm_appr"
  }[] = []

  let i = 0
  while (i < approvalSteps.length) {
    const targetStep = approvalSteps[i]

    // Set up our individual reviewer relationship
    const reviewersRelationship = targetStep.reviewers.map(
      (reviewer: UserUser) => {
        return {
          type: "user--user",
          id: reviewer.id,
        }
      }
    )

    // Set up our reviewer group relationship
    const reviewerGroupsRelationship = targetStep.reviewerGroups.map(
      (group: GroupGroup) => {
        return {
          type: "group--rev_group",
          id: group.relationships.entity_id.data.id,
        }
      }
    )

    // Set up our approval step
    const approvalStepBody: { data: PreApprovalStep } = {
      // @ts-ignore
      data: {
        type: "mm_appr--mm_appr",
        attributes: {
          label: targetStep.attributes.label,
          field_approver_instructions: {
            value: targetStep.attributes.field_approver_instructions,
          },
          field_step_name: targetStep.attributes.label,
          field_turn_time: targetStep.attributes.field_turn_time,
        },
        relationships: {
          field_reviewer: {
            data: reviewersRelationship,
          },
          field_reviewer_group: {
            data: reviewerGroupsRelationship,
          },
          field_form: {
            data: {
              type: "mm_form--pre_approval",
              id: formData.id,
            },
          },
        },
      },
    }

    let approvalStepResponse
    // If we've got an ID we're patching, otherwise we're creating
    if (targetStep.id) {
      approvalStepBody.data.id = targetStep.id

      approvalStepResponse = await fetchWrapper.patch(
        "/api/mm_appr/mm_appr/" + targetStep.id,
        session.token,
        JSON.stringify(approvalStepBody)
      )
    } else {
      approvalStepResponse = await fetchWrapper.post(
        "/api/mm_appr/mm_appr",
        session.token,
        JSON.stringify(approvalStepBody)
      )
    }

    if (approvalStepResponse.ok) {
      const approvalStepResponseData = await approvalStepResponse.json()

      // Add in what we'll need later on for our relationship on the form
      approvalStepsRelationship.push({
        id: approvalStepResponseData.data.id,
        type: approvalStepResponseData.data.type,
      })
    }

    i++
  }

  // And return it
  return approvalStepsRelationship
})

export const deletePreApprovalStep = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("preApprovalForms/deletePreApprovalStep", async (args, thunkAPI) => {
  const { session } = thunkAPI.getState()
  const { values, stepId } = args
  const { approvalSteps } = values

  if (stepId) {
    const formId = values.id

    const remainingSteps = approvalSteps.find(
      (step: PreApprovalStep) => step.id && step.id !== stepId
    )

    const targetStep = approvalSteps.find(
      (step: PreApprovalStep) => step.id === stepId
    )

    await fetchWrapper.remove(
      "/api/mm_appr/mm_appr/" + targetStep.id,
      session.token
    )

    const formUpdateBody: { data: PreApprovalFormEntity } = {
      data: {
        type: "mm_form--pre_approval",
        id: formId,
        relationships: {
          field_approval_steps: {
            data: remainingSteps.map((step: PreApprovalStep) => {
              return {
                id: step.id,
                type: "mm_appr--mm_appr",
              }
            }),
          },
        },
      },
    }

    await fetchWrapper.patch(
      "/api/mm_form/pre_approval/" + formId,
      session.token,
      JSON.stringify(formUpdateBody)
    )
  }

  return true
})

export const savePreApprovalForm = createAsyncThunk<
  any,
  any,
  { state: RootState }
>("preApprovalForms/savePreApprovalForm", async (args, thunkAPI) => {
  const { session } = thunkAPI.getState()
  const { values } = args
  let formResponse

  // Construct our initial form body so we can get our IDs back
  const formBody: { data: PreApprovalFormEntity } = {
    data: {
      type: "mm_form--pre_approval",
      attributes: {
        label: values.attributes.label,
        description: values.attributes.description,
      },
      relationships: {
        field_organization: {
          data: {
            type: "group--organization",
            id: session.group.uuid[0].value,
          },
        },
      },
    },
  }

  // POST or PATCH based on whether we have an ID already
  if (values.id) {
    formBody.data.id = values.id

    formResponse = await fetchWrapper.patch(
      "/api/mm_form/pre_approval/" + values.id,
      session.token,
      JSON.stringify(formBody)
    )
  } else {
    formResponse = await fetchWrapper.post(
      "/api/mm_form/pre_approval/",
      session.token,
      JSON.stringify(formBody)
    )
  }

  // If all good
  if (formResponse.ok) {
    // Pass the parent form data to the next step to save our questions
    const formResponseData = await formResponse.json()
    const questionResponse = await thunkAPI.dispatch(
      savePreApprovalQuestions({ values, formData: formResponseData.data })
    )

    const questionsRelationship = questionResponse.payload ?? []

    const approvalStepResponse = await thunkAPI.dispatch(
      savePreApprovalSteps({ values, formData: formResponseData.data })
    )

    const approvalStepsRelationship = approvalStepResponse.payload ?? []

    const updatedFormBody: { data: PreApprovalFormEntity } = {
      data: {
        id: formResponseData.data.id,
        type: "mm_form--pre_approval",
        relationships: {
          field_questions: {
            data: questionsRelationship,
          },
          field_approval_steps: {
            data: approvalStepsRelationship,
          },
        },
      },
    }

    const updatedFormResponse = await fetchWrapper.patch(
      "/api/mm_form/pre_approval/" + formResponseData.data.id,
      session.token,
      JSON.stringify(updatedFormBody)
    )

    if (updatedFormResponse.ok) {
      const updatedFormResponseData = await updatedFormResponse.json()
      return updatedFormResponseData.data
    }
  }
})

type InitialState = {
  isOpen: boolean
  fetched: boolean
  data: PreApprovalFormEntity[]
}

const initialState: InitialState = {
  isOpen: false,
  fetched: false,
  data: [],
}

export const preApprovalForms = createSlice({
  name: "preApprovalForms",
  initialState,
  reducers: {
    setPreApprovalFormsModalOpen: (state, action) => {
      state.isOpen = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPreApprovalForms.fulfilled, (state, action: any) => {
      state.fetched = true
      state.data = action.payload
    })
  },
})

export const { setPreApprovalFormsModalOpen } = preApprovalForms.actions

export default preApprovalForms.reducer
