import Loading from "@mobilemind/common/src/components/Loading"
import UserInitial from "@mobilemind/common/src/components/UserInitial"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  Tab,
  Tabs,
  TextField,
} from "@mui/material"
import classNames from "classnames"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useAppDispatch } from "store/hooks"
import ErrorBoundary from "../../components/ErrorBoundary"
import { ReactComponent as SearchIcon } from "../../img/search.svg"
import "../../styles/modal.scss"
import { UserSelectJobTitles } from "./UserSelectJobTitles"
import {
  clearSearch,
  getUserList,
  updateGroupSearch,
  updateUserSearch,
} from "./userSelectSlice"

const mapStateToProps = ({ userSelect, session, assessments }) => {
  return {
    userSelect,
    session,
    subGroups: session.subGroups.data.length
      ? session.subGroups
      : session.subgroups,
    reviewerGroups: assessments.reviewerGroups,
  }
}

function UserSelect(props) {
  const {
    userSelect,
    session,
    open,
    update,
    visibleTabs,
    userLabel,
    isGroupAdmin,
    reviewerGroups,
    maxSelectable,
    entity,
  } = props

  const dispatch = useAppDispatch()
  const [currentTab, setCurrentTab] = useState("subgroups")
  const [tabSet, setTabSet] = useState(false)

  useEffect(() => {
    if (props.initialTab && !tabSet) {
      setTabSet(true)
      setCurrentTab(props.initialTab)
      if (userLabel === "reviewers" && currentTab !== "users") {
        setCurrentTab("users")
      }
    }
  }, [userLabel, currentTab, dispatch, props.initialTab, tabSet])

  function handleTabChange(event, newValue) {
    setCurrentTab(newValue)
  }

  function handleSearch(event) {
    dispatch(updateUserSearch(event.target.value))
  }

  function onClose() {
    props.onClose()
    dispatch(clearSearch())
    dispatch(getUserList())
  }

  let personnelCount = entity?.personnel?.length ?? 0

  function handleClose(reason) {
    if (reason && reason === "backdropClick") {
      if (!props.disableBackdropClick) {
        onClose()
      }
    } else {
      onClose()
    }
  }

  return (
    <ErrorBoundary>
      <Dialog
        className={classNames(
          "user-select-modal",
          visibleTabs.includes("reviewerGroups") &&
            "reviewer-group-select-modal"
        )}
        onClose={(_, reason) => handleClose(reason)}
        open={open}
        maxWidth="xs"
        fullWidth={true}
        PaperProps={{
          sx: { height: 500 },
        }}
      >
        <DialogContent className="attendee-select" sx={{ padding: 0 }}>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            centered={true}
            sx={{ marginBlock: 4 }}
          >
            {visibleTabs.includes("groups") && (
              <Tab value="subgroups" label="Groups" />
            )}
            {visibleTabs.includes("job-titles") && (
              <Tab value="jobTitles" label="Job Titles" />
            )}
            {visibleTabs.includes("users") && (
              <Tab value="users" label={userLabel} />
            )}
            {visibleTabs.includes("reviewerGroups") && (
              <Tab value="reviewerGroups" label={"Reviewer Groups"} />
            )}
          </Tabs>
          {currentTab === "subgroups" && (
            <>
              <Box sx={{ alignSelf: "stretch", paddingInline: 4 }}>
                <TextField
                  variant="standard"
                  placeholder="Search groups"
                  value={userSelect.subGroupSearch}
                  onChange={(event) =>
                    dispatch(updateGroupSearch(event.target.value))
                  }
                  InputProps={{
                    startAdornment: <SearchIcon />,
                    sx: {
                      gap: 2,
                      "&>svg": {
                        width: 20,
                        height: 20,
                        opacity: 0.3,
                      },
                    },
                  }}
                />
              </Box>
              {!props.session.subGroups.fetched && (
                <Loading message="Getting groups..." sx={{ padding: 4 }} />
              )}
              <List className="event-select-list" sx={{ marginTop: 4 }}>
                {props.session?.subGroups.fetched &&
                  props.subGroups &&
                  props.subGroups.data && (
                    <>
                      {props.subGroups?.data.map((group, index) => {
                        const isActive =
                          entity?.subGroups &&
                          entity?.subGroups.find(
                            (current) =>
                              (current && current.id === group.id) ||
                              entity?.attendeeMethod === "all"
                          )

                        const matchesSearch =
                          !userSelect.subGroupSearch ||
                          (group.attributes.label &&
                            group.attributes.label
                              .toLowerCase()
                              .includes(
                                userSelect.subGroupSearch.toLowerCase()
                              ))

                        const subGroupIds =
                          session.subgroups &&
                          session.subgroups.data.map(
                            (subgroup) => subgroup.drupal_internal__id
                          )

                        if (
                          (matchesSearch &&
                            isGroupAdmin &&
                            subGroupIds.includes(group.entity_id)) ||
                          matchesSearch
                        ) {
                          return (
                            <ListItem
                              style={{
                                animationDelay: index * 0.05 + "s",
                              }}
                              onClick={() => {
                                dispatch(
                                  update({
                                    field: "subGroups",
                                    value: group,
                                    type: isActive ? "remove" : "add",
                                  })
                                )
                              }}
                              key={group.id}
                              className={classNames(
                                "event-select-item",
                                isActive && "active"
                              )}
                            >
                              <div className="flexRow">
                                <span className="icon group"></span>
                                <strong>{group.attributes.label}</strong>
                              </div>
                            </ListItem>
                          )
                        }

                        return null
                      })}
                    </>
                  )}
              </List>
            </>
          )}

          {currentTab === "jobTitles" && (
            <UserSelectJobTitles
              onAdd={(jobTitle) => {
                dispatch(
                  update({
                    field: "jobTitles",
                    value: jobTitle,
                    type: "add",
                  })
                )
              }}
              onRemove={(jobTitle) => {
                dispatch(
                  update({
                    field: "jobTitles",
                    value: jobTitle,
                    type: "remove",
                  })
                )
              }}
              value={entity.attendeeMethod === "all" ? "all" : entity.jobTitles}
            />
          )}

          {currentTab === "users" && (
            <div className="learner-search">
              <Box sx={{ alignSelf: "stretch", paddingInline: 4 }}>
                <TextField
                  variant="standard"
                  placeholder={"Search " + userLabel}
                  value={userSelect.learnerSearch}
                  onChange={(event) => handleSearch(event)}
                  InputProps={{
                    startAdornment: <SearchIcon />,
                    sx: {
                      gap: 2,
                      "& svg": {
                        width: 20,
                        height: 20,
                        opacity: 0.3,
                      },
                    },
                  }}
                />
              </Box>
              {userSelect.orgUsers.length < 1 &&
                !userSelect.learnerSearchActive && (
                  <>
                    {entity && entity.learnerSearch ? (
                      <p style={{ marginLeft: 20, fontSize: 14 }}>
                        <em>Nothing matches your search.</em>
                      </p>
                    ) : (
                      <p style={{ marginLeft: 20, fontSize: 14 }}>
                        <em>{props.searchText}</em>
                      </p>
                    )}
                  </>
                )}

              {userSelect.learnerSearchActive && (
                <Loading
                  message={"Getting " + userLabel + "..."}
                  sx={{ padding: 4 }}
                />
              )}

              <List className="event-select-list" sx={{ marginTop: 4 }}>
                {userSelect.orgUsers.length > 0 &&
                  userSelect.orgUsers.map((user, index) => {
                    let isActive

                    if (props.isPersonnel) {
                      isActive = entity.personnel.find(
                        (current) => current.id === user.uuid
                      )
                    } else if (entity && entity.attendees) {
                      isActive =
                        entity.attendees.find(
                          (current) => current.id === user.uuid
                        ) || entity.attendeeMethod === "all"
                    } else {
                      isActive =
                        entity?.reviewers &&
                        entity.reviewers.find(
                          (current) => current.id === user.uuid
                        )

                      if (props.selectedUsers) {
                        isActive =
                          props.selectedUsers &&
                          props.selectedUsers.find(
                            (current) => current.id === user.uuid
                          )
                      }
                    }

                    if (props.currentReviewerGroup) {
                      isActive = props.currentReviewerGroup.members.find(
                        (member) => {
                          return (
                            Number(user.uid) ===
                            member.relationships?.entity_id.data.meta
                              .drupal_internal__target_id
                          )
                        }
                      )
                    }
                    return (
                      <ListItem
                        style={{
                          animationDelay: index * 0.05 + "s",
                          pointerEvents: personnelCount >= 25 ? "none" : "all",
                        }}
                        key={user.uuid}
                        className={classNames(
                          "event-select-item",
                          isActive && "active"
                        )}
                        onClick={() => {
                          if (props.isPersonnel || props.currentReviewerGroup) {
                            update({
                              user,
                              type: isActive ? "remove" : "add",
                            })
                          } else {
                            let type = isActive ? "remove" : "add"
                            if (maxSelectable) {
                              type = "replace"
                            }

                            if (props.entity.type === "mm_appr--mm_appr") {
                              update({
                                field: "attendees",
                                value: user,
                                type,
                              })
                            } else {
                              dispatch(
                                update({
                                  field:
                                    userLabel === "reviewers"
                                      ? "reviewers"
                                      : "attendees",
                                  value: user,
                                  type,
                                })
                              )
                            }
                          }
                        }}
                      >
                        <div
                          className="flexRow"
                          style={{ opacity: personnelCount === 25 ? 0.5 : 1 }}
                        >
                          {!user.user_picture ||
                          (user.user_picture &&
                            user.user_picture.includes("default_temp")) ? (
                            <UserInitial
                              containerStyle={{ marginRight: 10 }}
                              size={40}
                              initials={
                                user.field_first_name.toUpperCase().charAt(0) +
                                user.field_last_name.toUpperCase().charAt(0)
                              }
                            />
                          ) : (
                            <span
                              className="userPicture icon"
                              style={{
                                backgroundImage:
                                  "url(" +
                                  process.env.REACT_APP_API_URL +
                                  user.user_picture +
                                  ")",
                              }}
                            />
                          )}
                          <div className="flexRow">
                            <strong className="learner-name">
                              {user.field_first_name &&
                                user.field_first_name.toLowerCase()}{" "}
                              {user.field_last_name &&
                                user.field_last_name.toLowerCase()}
                            </strong>
                            <span>
                              {user.field_subgroup ? (
                                <>{user.field_subgroup}</>
                              ) : (
                                <>{props.session.group.label[0].value}</>
                              )}
                              {!props.isPersonnel && user.group_roles && (
                                <span
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    opacity: 1,
                                    textTransform: "none",
                                  }}
                                >
                                  <br />
                                  {user.group_roles
                                    .replaceAll(", Teacher", "")
                                    .replaceAll("*", "")}
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                      </ListItem>
                    )
                  })}
              </List>
            </div>
          )}

          {currentTab === "reviewerGroups" && (
            <div className="learner-search">
              <List className="event-select-list">
                {reviewerGroups.data.length > 0 &&
                  reviewerGroups.data.map((reviewerGroup, index) => {
                    const isActive = entity.reviewerGroups.find((group) => {
                      if (reviewerGroup.relationships.entity_id) {
                        return (
                          group.id ===
                            reviewerGroup.relationships.entity_id.data.id ||
                          group.attributes.drupal_internal__id ===
                            reviewerGroup.attributes.drupal_internal__id
                        )
                      }
                      return false
                    })
                    return (
                      <ListItem
                        style={{
                          animationDelay: index * 0.05 + "s",
                        }}
                        key={reviewerGroup.id}
                        className={classNames(
                          "event-select-item",
                          isActive && "active"
                        )}
                        onClick={() => {
                          if (props.entity.type === "mm_appr--mm_appr") {
                            update({
                              field: "reviewerGroups",
                              value: reviewerGroup.relationships.entity_id
                                ? reviewerGroup.relationships.entity_id.data
                                : reviewerGroup.meta.drupal_internal__target_id,
                              type: isActive ? "remove" : "add",
                            })
                          } else {
                            dispatch(
                              update({
                                field: "reviewerGroups",
                                value: reviewerGroup.relationships.entity_id
                                  ? reviewerGroup.relationships.entity_id.data
                                  : reviewerGroup.meta
                                      .drupal_internal__target_id,
                                type: isActive ? "remove" : "add",
                              })
                            )
                          }
                        }}
                      >
                        <div className="flexRow">
                          <span className="icon reviewer-group" />
                          <strong className="learner-name">
                            {reviewerGroup.attributes.label}
                          </strong>
                        </div>
                      </ListItem>
                    )
                  })}
              </List>
            </div>
          )}
        </DialogContent>

        <DialogActions
          sx={{
            padding: 2,
            justifyContent: "flex-end",
          }}
        >
          {props.isPersonnel && personnelCount === 25 && (
            <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
              <span
                className="icon info"
                style={{ width: 15, height: 15, marginRight: 5 }}
              />
              <strong style={{ fontSize: 12, fontWeight: "bold" }}>
                You've added the maximum number of personnel.
              </strong>
            </div>
          )}
          <Button
            className="button small"
            onClick={() => {
              onClose()
            }}
          >
            {props.confirmLabel ?? <>Done</>}
          </Button>
        </DialogActions>
      </Dialog>
    </ErrorBoundary>
  )
}

export default connect(mapStateToProps)(UserSelect)
