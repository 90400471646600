import { useEffect } from "react"

import {
  fetchBackpack,
  fetchCompletedCourses,
  fetchCompletedLPs,
  fetchEarnedBadges,
  fetchEventsAttended,
  fetchExternalApprovals,
  fetchMandatedTrainingHistory,
  fetchUserRubrics,
} from "@mobilemind/common/src/actions/backpack"

import Loading from "@mobilemind/common/src/components/Loading"

import UserInitial from "@mobilemind/common/src/components/UserInitial"
import moment from "moment"

import "./backpack-print.scss"
import "./backpack.scss"

import TimeCapsule from "@mobilemind/common/src/components/TimeCapsule"

import ContentRow from "@mobilemind/common/src/components/backpack/ContentRow"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"

import { withRouter } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../store/hooks"

const BackpackPrintLayout = (props: any) => {
  const backpack = useAppSelector((state) => state.backpack)
  const session = useAppSelector((state) => state.session)

  const { orgRoles, groupRoles } = session

  const allRoles = orgRoles.concat(groupRoles).join(",")

  const isAdmin =
    allRoles.includes("organization-admin") || allRoles.includes("group-admin")

  const isObserver =
    allRoles.includes("observer") || allRoles.includes("observation")

  const isRubricsOnly = isObserver && !isAdmin

  const dispatch = useAppDispatch()

  const params = new URLSearchParams(props.location.search)
  const userId = Number(props.match.params.id)

  let dateRange = params.get("dateRange")
  let dateMin = params.get("dateMin")
  let dateMax = params.get("dateMax")
  let category = params.get("category")

  useEffect(() => {
    const filters = {
      dateRange,
      dateMin,
      dateMax,
      category,
      print: true,
    }

    if (!backpack.fetched) {
      if (!isRubricsOnly) {
        dispatch(fetchCompletedLPs(filters, userId))
        dispatch(fetchExternalApprovals(filters, userId))
        dispatch(fetchEventsAttended(filters, userId))
        dispatch(fetchEarnedBadges(filters, userId))
        dispatch(fetchCompletedCourses(filters, userId))
        dispatch(fetchMandatedTrainingHistory(filters, userId))
        dispatch(fetchBackpack(filters, userId))
        if (isObserver) {
          dispatch(fetchUserRubrics(filters, userId))
        }
      } else {
        dispatch(fetchUserRubrics(filters, userId))
      }
    }
  }, [
    backpack.fetched,
    dateRange,
    dateMin,
    dateMax,
    category,
    isRubricsOnly,
    isObserver,
    userId,
    dispatch,
  ])

  const hours =
    backpack.totalTime >= 60
      ? Math.floor(backpack.totalTime / 60).toLocaleString()
      : 0
  const minutes = backpack.totalTime % 60
  let ContentType: Array<
    | "events"
    | "external"
    | "courses"
    | "badges"
    | "learningPaths"
    | "userRubrics"
    | "mandatedTraining"
  > = [
    "events",
    "external",
    "courses",
    "badges",
    "learningPaths",
    "userRubrics",
    "mandatedTraining",
  ]

  const orderedSections = [
    "events",
    "external",
    "courses",
    "badges",
    "learningPaths",
    "userRubrics",
    "mandatedTraining",
  ]

  if (!backpack.events.data.length) {
    orderedSections.push(
      orderedSections.splice(orderedSections.indexOf("events"), 1)[0]
    )
  }
  if (!backpack.external.data.length) {
    orderedSections.push(
      orderedSections.splice(orderedSections.indexOf("external"), 1)[0]
    )
  }
  if (!backpack.courses.data.length) {
    orderedSections.push(
      orderedSections.splice(orderedSections.indexOf("courses"), 1)[0]
    )
  }
  if (!backpack.badges.data.length) {
    orderedSections.push(
      orderedSections.splice(orderedSections.indexOf("badges"), 1)[0]
    )
  }
  if (!backpack.learningPaths.data.length) {
    orderedSections.push(
      orderedSections.splice(orderedSections.indexOf("learningPaths"), 1)[0]
    )
  }
  if (!backpack.userRubrics.data.length && !isRubricsOnly) {
    orderedSections.push(
      orderedSections.splice(orderedSections.indexOf("userRubrics"), 1)[0]
    )
  }
  if (!backpack.mandatedTraining.data.length) {
    orderedSections.push(
      orderedSections.splice(orderedSections.indexOf("mandatedTraining"), 1)[0]
    )
  }

  let isReady =
    backpack.fetched &&
    backpack.courses.fetched &&
    backpack.badges.fetched &&
    backpack.learningPaths.fetched &&
    backpack.events.fetched &&
    backpack.external.fetched &&
    backpack.mandatedTraining.fetched

  if (isRubricsOnly) {
    isReady = backpack.userRubrics.fetched
  }

  let dateFromFormat = "MMMM Do"
  if (dateRange !== "all") {
    if (
      moment().startOf("week").format("YYYY") !==
      moment().endOf("week").format("YYYY")
    ) {
      dateFromFormat = "MMMM DD YYYY"
    }
  }

  return (
    <div className="page backpack-layout print">
      {isReady && (
        <div id="floatingButton">
          <ButtonLarge onClick={() => window.print()}>
            <span className="icon document" />
            Download PDF
          </ButtonLarge>
        </div>
      )}

      {isReady && (
        <header id="report-header">
          <div className="flexRow" style={{ marginBottom: 20 }}>
            <div className="flexRow userContainer">
              <div
                style={{
                  position: "relative",
                  zIndex: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {backpack.userData.user_image &&
                !backpack.userData.user_image.includes("default_temp") ? (
                  <img
                    alt={"Profile"}
                    src={backpack.userData.user_image}
                    className="userPicture"
                  />
                ) : (
                  <UserInitial
                    fontSize={32}
                    size={80}
                    initials={
                      backpack.userData.field_first_name_value
                        .charAt(0)
                        .toUpperCase() +
                      backpack.userData.field_last_name_value
                        .charAt(0)
                        .toUpperCase()
                    }
                  />
                )}
                <div className="userInfo" style={{ marginLeft: 15 }}>
                  <strong>
                    {backpack.userData.field_first_name_value}{" "}
                    {backpack.userData.field_last_name_value}
                  </strong>
                  {backpack.userData.user_subgroups && (
                    <span> {backpack.userData.user_subgroups}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="timeInfo">
              <header className="date-header">
                {dateRange === "all" && <>All Time PD Credit</>}
                {dateRange === "week" && (
                  <>
                    {moment().startOf("week").format(dateFromFormat)} -{" "}
                    {moment().endOf("week").format("MMMM D, YYYY")}
                  </>
                )}
                {dateRange === "month" && (
                  <>
                    {moment().startOf("month").format(dateFromFormat)} -{" "}
                    {moment().endOf("month").format("MMMM D, YYYY")}
                  </>
                )}
                {dateRange === "year" && (
                  <>
                    {moment().startOf("year").format(dateFromFormat)} -{" "}
                    {moment().endOf("year").format("MMMM D, YYYY")}
                  </>
                )}
                {dateRange === "custom" && (
                  <>
                    {dateMin ? (
                      <>{moment(JSON.parse(dateMin)).format("MMMM D YYYY")}</>
                    ) : (
                      <>Beginning of Time</>
                    )}
                    {dateMax && (
                      <>
                        {" "}
                        - {moment(JSON.parse(dateMax)).format("MMMM D, YYYY")}
                      </>
                    )}
                  </>
                )}
              </header>

              <strong className="totalTime">
                {Number(hours) > 0 && <>{hours}h</>} {minutes}m
              </strong>
            </div>
          </div>

          {
            <ul className="topCategories allCategories">
              {backpack.categoryData.map((cat: any) => {
                let categoryHours =
                  cat.total_time >= 60
                    ? Math.floor(cat.total_time / 60).toLocaleString()
                    : 0
                const categoryMinutes = cat.total_time % 60

                if (cat.tid && cat.total_time) {
                  return (
                    <li
                      key={cat.tid}
                      style={{
                        backgroundColor: "white",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        alt={"category"}
                        className="categoryIcon"
                        src={cat.category_image}
                      />
                      <div
                        style={{
                          marginLeft: 5,
                          flex: 1,
                          position: "relative",
                          top: -2,
                        }}
                      >
                        <strong style={{ display: "block", marginBottom: -5 }}>
                          {cat.category_name}
                        </strong>
                      </div>
                      <TimeCapsule
                        hours={Number(categoryHours)}
                        minutes={Number(categoryMinutes)}
                      />
                    </li>
                  )
                }
                return null
              })}
            </ul>
          }
        </header>
      )}

      {isReady ? (
        <>
          {Object.values(ContentType).map((section, index) => {
            let contentBase
            let shouldReturn = true
            if (section === "events") {
              contentBase = backpack.events
            }
            if (section === "external") {
              contentBase = backpack.external
            }
            if (section === "courses") {
              contentBase = backpack.courses
            }
            if (section === "badges") {
              contentBase = backpack.badges
            }
            if (section === "learningPaths") {
              contentBase = backpack.learningPaths
            }
            if (section === "userRubrics") {
              contentBase = backpack.userRubrics
              if (!backpack.userRubrics.data.length) {
                shouldReturn = false
              }
            }
            if (section === "mandatedTraining") {
              contentBase = backpack.mandatedTraining
              if (!backpack.mandatedTraining.data.length) {
                shouldReturn = false
              }
            }

            if (
              section === "badges" ||
              section === "learningPaths" ||
              section === "userRubrics"
            ) {
              if (backpack.filters.category.length > 0) {
                shouldReturn = false
              }
            }

            if (isRubricsOnly && section !== "userRubrics") {
              shouldReturn = false
            }
            if (shouldReturn && contentBase) {
              return (
                <ContentRow
                  backpack={backpack}
                  categories={props.categories}
                  changeNavbar={props.changeNavbar}
                  goTo={props.goTo}
                  session={props.session}
                  setBadgeCarousel={props.setBadgeCarousel}
                  setCourseCarousel={props.setCourseCarousel}
                  setEventsCarousel={props.setEventsCarousel}
                  setLPCarousel={props.setLPCarousel}
                  setRubricCarousel={props.setRubricCarousel}
                  increaseBadgePage={props.increaseBadgePage}
                  increaseCoursePage={props.increaseCoursePage}
                  increaseEventsPage={props.increaseEventsPage}
                  increaseLPPage={props.increaseLPPage}
                  increaseRubricPage={props.increaseRubricPage}
                  increaseMandatedTrainingPage={
                    props.increaseMandatedTrainingPage
                  }
                  setMandatedTrainingCarousel={
                    props.setMandatedTrainingCarousel
                  }
                  setExternalModal={props.setExternalModal}
                  // eslint-disable-next-line
                  // @ts-ignore
                  setDateMin={props.setDateMin}
                  // eslint-disable-next-line
                  // @ts-ignore
                  setDateMax={props.setDateMax}
                  // {...props}
                  print={true}
                  key={index}
                  animationDelay={index}
                  total={contentBase.totalCompleted}
                  data={contentBase.data}
                  contentType={section}
                />
              )
            }
            return null
          })}
        </>
      ) : (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="backpack-buddy">
            <div className="star" />
            <div className="star" />
            <div className="star" />
          </div>
          <Loading message={"Generating backpack..."} />
        </div>
      )}
    </div>
  )
}

export default withRouter(BackpackPrintLayout)
