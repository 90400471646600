import Loading from "@mobilemind/common/src/components/Loading"
import { useDialogState } from "@mobilemind/common/src/hooks/useDialogState"
import { Box, Paper } from "@mui/material"
import { Form, Formik, FormikHelpers } from "formik"
import "react-quill/dist/quill.snow.css"
import { useAppSelector } from "store/hooks"
import { selectUserCanShare } from "store/selectors"
import "../../../styles/create.scss"
import { CourseFormNavbar } from "../CourseFormNavbar"
import { ModalCourseShare } from "../ModalCourseShare"
import { useCourseFormSchema } from "../hooks/useCourseFormSchema"
import { CourseFormValues } from "../types"
import { CourseFormChallenge } from "./CourseFormChallenge"
import { CourseFormOverview } from "./CourseFormOverview"
import { CourseFormReview } from "./CourseFormReview"
import { CourseFormSettings } from "./CourseFormSettings"
import { CourseFormStep, CourseFormStepper } from "./CourseFormStepper"
import { GenieActiveSwitch } from "./GenieActiveSwitch"
import { GenieProvider } from "./GenieProvider"

type CourseFormProps = {
  initialValues: CourseFormValues
  onSubmit: (
    values: CourseFormValues,
    helpers: FormikHelpers<CourseFormValues>
  ) => Promise<void>
}

export const CourseForm = (props: CourseFormProps) => {
  const { initialValues, onSubmit } = props

  const userCanShare = useAppSelector(selectUserCanShare)

  const validationSchema = useCourseFormSchema()
  const { isDialogOpen, openDialog, closeDialog } = useDialogState()

  const handleSubmit = async (
    values: CourseFormValues,
    helpers: FormikHelpers<CourseFormValues>
  ) => {
    const { setSubmitting } = helpers
    if (
      userCanShare &&
      (!values.id || values.field_draft) &&
      !values.isFromTemplate
      // @todo validate the logic here matches the logic controlling the render
      // of the ModalCourseShare below.
    ) {
      setSubmitting(false)
      openDialog()
    } else {
      return onSubmit(values, helpers)
    }
  }

  return (
    <Formik
      initialStatus={{
        activeStep: CourseFormStep.Overview,
        furthestStep:
          initialValues.id && !initialValues.field_draft
            ? CourseFormStep.Review
            : CourseFormStep.Overview,
      }}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {({ errors, isSubmitting, status, values }) => (
        <Form>
          <>
            {console.debug(errors)}
            {isSubmitting && (
              <Loading fullPage={true} message="Saving course…" />
            )}
            <CourseFormNavbar openShareDialog={openDialog} />
            {userCanShare &&
              !values.isTemplate &&
              !values.shareSettings.includes("community") && (
                <ModalCourseShare open={isDialogOpen} onClose={closeDialog} />
              )}
            <GenieProvider
              sx={{
                marginBlockStart: "100px",
                position: "relative",
                paddingBlock: 5,
              }}
            >
              <CourseFormStepper />
              <Paper sx={{ position: "relative", marginBlockStart: 4 }}>
                <Box
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    marginTop: 2,
                    marginRight: 2,
                    zIndex: 3,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <GenieActiveSwitch />
                </Box>
                {status.activeStep === CourseFormStep.Overview && (
                  <CourseFormOverview />
                )}
                {status.activeStep === CourseFormStep.Challenge && (
                  <CourseFormChallenge />
                )}
                {status.activeStep === CourseFormStep.Settings && (
                  <CourseFormSettings />
                )}
                {status.activeStep === CourseFormStep.Review && (
                  <CourseFormReview />
                )}
              </Paper>
            </GenieProvider>
          </>
        </Form>
      )}
    </Formik>
  )
}
