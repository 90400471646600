import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import debounceThunk from "@mobilemind/common/src/functions/debounceThunk"

import qs from "qs"
import moment from "moment"

export const getLeaderboard = createAsyncThunk(
  "leaderboardsSlice/getLeaderboard",
  async (args, thunkAPI) => {
    const filters = args ? args : thunkAPI.getState().leaderboards.filters
    const { currentPage } = thunkAPI.getState().leaderboards
    const { subGroups } = thunkAPI.getState().session
    const { session } = thunkAPI.getState()
    const {
      sortOrder,
      group,
      leaderboardType,
      category,
      dateFrom,
      dateTo,
      searchQuery,
      schoolHours,
    } = filters

    const isAdmin =
      session.orgRoles.includes("organization-admin") ||
      session.orgRoles.includes("organization-reporter")

    let orgId = session.group.id[0].value
    if (!isAdmin) {
      orgId = filters.groupAdminGroup
        ? filters.groupAdminGroup
        : session.subgroups.data[0].drupal_internal__id
    }

    let baseUrl = isAdmin ? "/api/leaderboard-" : "/api/group-leaderboard-"
    let url = baseUrl + leaderboardType + "/" + orgId + "?"
    let totalsUrl =
      "/api/leaderboard-" + leaderboardType + "-total/" + orgId + "?"

    let query = {
      sort_order: sortOrder,
      page: leaderboardType.includes("time-spent")
        ? { offset: currentPage * 50 }
        : currentPage,
      search: searchQuery,
    }

    if (category?.id) {
      query.category = category.attributes.drupal_internal__tid

      if (leaderboardType.includes("time-spent")) {
        query.category = [category.attributes.drupal_internal__tid]
      }
    }

    if (leaderboardType.includes("time-spent")) {
      url = "/api/time-spent-leaderboard?"
      totalsUrl = "/api/leaderboard-time-spent-total/" + orgId + "?"

      if (schoolHours !== "all") {
        query.school_hours = schoolHours
      }

      if (leaderboardType === "time-spent") {
        query.sort_by = "total_time_spent"
      }
      if (leaderboardType === "time-spent-course") {
        query.sort_by = "assessment_time_spent"
      }
      if (leaderboardType === "event-time-spent") {
        query.sort_by = "event_time_spent"
      }
      if (leaderboardType === "ext-event-time-spent") {
        query.sort_by = "ext_usr_event_time_spent"
      }
    }

    let subGroup = isAdmin
      ? subGroups.data.find((group) => group.id === filters.group)
      : session.subgroup

    if (
      (group !== "all" || !isAdmin) &&
      !leaderboardType.includes("time-spent")
    ) {
      if (isAdmin) {
        url = baseUrl + leaderboardType + "/" + subGroup.entity_id + "?"
        totalsUrl =
          "/api/leaderboard-" +
          leaderboardType +
          "-total/" +
          subGroup.entity_id +
          "?"
        query.field_subgroup = subGroup.entity_id
      } else {
        totalsUrl =
          "/api/leaderboard-" +
          leaderboardType +
          "-total/" +
          session.group.id[0].value +
          "?"
      }
    }

    if (
      (group !== "all" || !isAdmin) &&
      leaderboardType.includes("time-spent")
    ) {
      totalsUrl =
        "/api/leaderboard-time-spent-total/" + session.group.id[0].value + "?"
      // this is really the subgroup
      if (!isAdmin) {
        query.field_subgroup = orgId
      } else {
        query.field_subgroup = subGroup.entity_id
      }
    } else if (subGroup) {
      query.field_subgroup = subGroup.entity_id
    }

    let dateRange = {}

    if (dateFrom) {
      dateRange.min = dateFrom.split("T")[0].replace('"', "")
    }
    if (dateTo) {
      dateRange.max = dateTo.split("T")[0].replace('"', "")
    }

    if ((dateFrom || dateTo) && !leaderboardType.includes("time-spent")) {
      if (leaderboardType === "badges-earned") {
        query.created = dateRange
      } else {
        query.changed = dateRange
      }
    }

    if (leaderboardType.includes("time-spent")) {
      if (dateFrom) {
        query.date_from = moment(dateFrom, "YYYY-MM-DD").unix()
      }
      if (dateTo) {
        query.date_to = moment(dateTo, "YYYY-MM-DD")
          .add(19, "hours")
          .add(59, "minutes")
          .unix()
      }
    }

    let totalsQuery = {
      sort_order: query.sort_order,
      field_subgroup_value: query.field_subgroup,
    }
    if (category?.id) {
      totalsQuery.category = category.attributes.drupal_internal__tid
      if (leaderboardType.includes("time-spent")) {
        totalsQuery.category = [category.attributes.drupal_internal__tid]
      }
    }

    if (dateFrom) {
      totalsQuery.changed = dateRange
    }

    let response = await fetchWrapper.get(url + qs.stringify(query))
    let totals = await fetchWrapper.get(totalsUrl + qs.stringify(totalsQuery))

    let data = await response.json()
    let totalsData = await totals.json()

    if (totals.ok) {
      if (!leaderboardType.includes("time-spent")) {
        data.total = totalsData.pager.total_items
      } else {
        if (leaderboardType === "time-spent") {
          data.total = totalsData.total_time_spent
        }
        if (leaderboardType === "time-spent-course") {
          data.total = totalsData.assessment_time_spent
        }
        if (leaderboardType === "event-time-spent") {
          data.total = totalsData.event_time_spent
        }
        if (leaderboardType === "ext-event-time-spent") {
          data.total = totalsData.ext_event_time_spent
        }
      }
    }

    return data
  }
)

const debouncedGetLeaderboard = debounceThunk(getLeaderboard, 600)

export const getPartnerLeaderboard = createAsyncThunk(
  "leaderboardsSlice/getPartnerLeaderboard",
  async (args, thunkAPI) => {
    const { leaderboards } = thunkAPI.getState()
    const { group } = thunkAPI.getState().session
    const filters = leaderboards.partnerFilters

    let query = {
      sort_order: filters.sortOrder,
      page: {
        offset: leaderboards.currentPage * 25,
      },
    }

    if (leaderboards.dateOption !== "allTime") {
      query.start = moment().startOf(leaderboards.dateOption).format("Y-MM-DD")
      query.end = moment().endOf(leaderboards.dateOption).format("Y-MM-DD")
    }

    let leaderboard = await fetchWrapper.get(
      "/api/mm_partner_portal/" +
        filters.leaderboardType +
        "/" +
        group.id[0].value +
        "?" +
        qs.stringify(query)
    )

    if (leaderboard.ok) {
      const data = await leaderboard.json()
      return data
    }
  }
)

const debouncedGetPartnerLeaderboard = debounceThunk(getPartnerLeaderboard, 600)

export const updateFilters = createAsyncThunk(
  "leaderboardsSlice/updateFilters",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetLeaderboard())
    return args
  }
)

export const updatePartnerFilters = createAsyncThunk(
  "leaderboardsSlice/updatePartnerFilters",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetPartnerLeaderboard())
    return args
  }
)

export const resetFilters = createAsyncThunk(
  "leaderboardsSlice/resetFilters",
  async (args, thunkAPI) => {
    const filters = {
      sortOrder: "DESC",
      leaderboardType: "courses-completed",
      group: "all",
      dateFrom: null,
      dateTo: moment().format("YYYY-MM-DDTHH:MM:SS"),
      searchInput: "",
      schoolHours: "all",
      category: [],
    }

    return filters
  }
)

const today = new Date()

export const leaderboardsSlice = createSlice({
  name: "leaderboardsSlice",
  initialState: {
    data: [],
    total: 0,
    currentPage: 0,
    searchQuery: "",
    dateOption: "allTime",
    filters: {
      active: false,
      sortOrder: "DESC",
      leaderboardType: "courses-completed",
      group: "all",
      category: null,
      groupAdminGroup: null,
      dateFrom: null,
      dateTo: moment().format("YYYY-MM-DDTHH:MM:SS"),
      searchInput: "",
      schoolHours: "all",
    },
    partnerFilters: {
      active: false,
      sortOrder: "DESC",
      leaderboardType: "course_leaderboard",
      start: null,
      end: JSON.stringify(new Date(today)),
    },
  },
  reducers: {
    updateField: (state, action) => {
      state[action.payload.field] = action.payload.value
    },
  },
  extraReducers: {
    [resetFilters.pending]: (state) => {
      state.data = []
      state.currentPage = 0
    },
    [resetFilters.fulfilled]: (state, action) => {
      state.filters = action.payload
    },
    [updateFilters.pending]: (state, action) => {
      state.filters[action.meta.arg.name] = action.meta.arg.value
    },
    [updateFilters.fulfilled]: (state, action) => {
      state.fetching = true

      if (action.payload.name === "increaseCurrentPage") {
        state.currentPage++
      } else {
        state.data = []
        state.currentPage = 0
        state.totalPages = 0
        state.filters[action.payload.name] = action.payload.value
      }

      state.filters[action.payload.name] = action.payload.value
    },
    [updatePartnerFilters.pending]: (state, action) => {
      state.partnerFilters[action.meta.arg.name] = action.meta.arg.value
    },
    [updatePartnerFilters.fulfilled]: (state, action) => {
      state.fetching = true

      if (action.payload.name === "increaseCurrentPage") {
        state.currentPage++
      } else {
        state.data = []
        state.currentPage = 0
        state.totalPages = 0
      }

      state.partnerFilters[action.payload.name] = action.payload.value
    },
    [getLeaderboard.pending]: (state) => {
      state.fetching = true
    },
    [getLeaderboard.rejected]: (state) => {
      state.fetching = false
    },
    [getLeaderboard.fulfilled]: (state, action) => {
      state.fetching = false

      let leaderboardType = state.filters.leaderboardType
      let results

      if (leaderboardType.includes("time-spent")) {
        results = action.payload.lb_query_data
        state.totalPages = Math.ceil(action.payload.total_records / 50)
      } else {
        results = action.payload.rows.content ? [] : action.payload.rows
        state.totalPages = action.payload.pager.total_pages
      }

      state.total = action.payload.total ? Number(action.payload.total) : 0

      if (!state.currentPage) {
        state.data = results
      } else {
        results.forEach((row) => {
          let existing = state.data.some(
            (existing) => existing.uuid === row.uuid
          )
          if (!existing) {
            state.data.push(row)
          }
        })
      }
    },

    [getPartnerLeaderboard.fulfilled]: (state, action) => {
      state.fetching = false

      let leaderboardType = state.partnerFilters.leaderboardType
      let data

      state.totalPages = Math.ceil(action.payload.total_records / 25)
      if (leaderboardType === "course_leaderboard") {
        data = action.payload?.partner_course_data ?? []
        state.total = action.payload.total_partner_courses_completed
      }

      if (leaderboardType === "badges_leaderboard") {
        data = action.payload?.partner_badges_data ?? []
        state.total = action.payload.total_partner_badges_earned
      }

      if (leaderboardType === "lp_leaderboard") {
        data = action.payload?.partner_lp_data ?? []
        state.total = action.payload.total_partner_lps_completed
      }

      if (!state.currentPage) {
        state.data = data
      } else {
        data.forEach((row) => {
          let existing = state.data.some(
            (existing) => existing.entity_id === row.entity_id
          )
          if (!existing) {
            state.data.push(row)
          }
        })
      }
    },
  },
})

export const {
  setLeaderboardType,
  setDateFrom,
  increaseCurrentPage,
  setDateTo,
  setGroup,
  setSortOrder,
  setSearchInput,
  setSearchQuery,
  updateField,
} = leaderboardsSlice.actions

export default leaderboardsSlice.reducer
