import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import { filterByPartners } from "@mobilemind/common/src/functions/index"
import {
  AnswerKeyCourseAnswers,
  CourseEntityCourseEntity,
  CourseTemplateCourseTemplate,
  QuizEntityCourseQuiz,
  TaxonomyTermCategory,
} from "@mobilemind/common/src/types/jsonapi"
import type { ModalCourseShareFormValues } from "features/courseForm/ModalCourseShare"
import {
  finishCourseGenie,
  selectCourseGenieActive,
} from "features/courseForm/store/courseGenieSlice"
import { CourseFormValues } from "features/courseForm/types"
import { chunk, sortBy } from "lodash"
import moment from "moment"
import qs from "qs"
import { AppDispatch, RootState } from "store/types"
import { generateQuizQuestionsRequest } from "../functions/quiz"
import { getOrgSuccessChecklist } from "../store/reducers/session"

export function getOrgCourses(filters: any, templates: boolean) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { session, entityList } = getState()

    filters = templates
      ? entityList.orgCourses.templates.filters
      : entityList.orgCourses.filters

    let url
    let query: any = {
      sort_by: filters.sortBy,
    }
    const groupRoles = session.groupRoles
    const isGroupAdmin =
      !session.orgRoles.includes("organization-admin") &&
      !session.orgRoles.includes("organization-creator") &&
      !session.orgRoles.includes("organization-drafter") &&
      (groupRoles.includes("group-admin") ||
        groupRoles.includes("group-creator") ||
        groupRoles.includes("group-drafter"))

    if (filters.state && filters.state.abbreviation) {
      query.org_state = filters.state.abbreviation
    }

    if (!filters.status) {
      query.field_draft_value = 1
    }
    if (filters.subGroup !== "any") {
      query.field_subgroup_target_id = filters.subGroup
    }
    if (filters.subGroup === "all") {
      query.field_subgroup_target_id = 0
    }
    if (filters.jobTitle !== "any") {
      query.field_job_title_target_id = filters.jobTitle
    }
    if (filters.status === 1 || templates) {
      query.field_archive_value = 0
      query.field_draft_value = 0
    }
    if (filters.status === "archived") {
      query.field_archive_value = 1
    }
    if (filters.organization !== "any") {
      query.field_organization_target_id =
        filters.organization &&
        filters.organization.attributes.drupal_internal__id
    }

    query.sort_order = filters.sortOrder

    if (filters.category && filters.category !== "any") {
      query.field_category_target_id =
        filters.category.attributes.name +
        " (" +
        filters.category.attributes.drupal_internal__tid +
        ")"
    }

    if (templates) {
      if (filters.category) {
        query.field_category = filters.category.attributes.drupal_internal__tid
      }
      query.field_creator_org = filters.source

      url = "/api/community_courses"
    } else {
      if (isGroupAdmin && !session.isSiteAdmin) {
        let groupIds =
          session.subgroups.data &&
          session.subgroups.data
            // @ts-ignore
            .map(
              (group) =>
                group.attributes?.drupal_internal__id ??
                // @ts-ignore
                group.drupal_internal__id
            )
            .join("+")

        if (filters.orgLevel) {
          url =
            "/api/course_entity/course_list/" +
            session.group.id[0].value +
            "/all"
        } else {
          url =
            "/api/course_entity/course_list/" +
            (session.group && session.group.type[0].target_id === "partner"
              ? "partner/"
              : "") +
            session.group.id[0].value +
            "/" +
            groupIds
        }
      } else if (!session.isSiteAdmin) {
        if (session.group.id) {
          url =
            "/api/course_entity/course_list/" +
            (session.group && session.group.type[0].target_id === "partner"
              ? "partner/"
              : "") +
            session.group.id[0].value +
            "/all"
        }
      }
      if (session.isSiteAdmin) {
        url = "/api/course_entity/mm_course_list"
      }
    }

    try {
      if (url) {
        let content: any[] = []
        let pages
        let fetched

        if (!templates) {
          query.page = entityList.orgCourses.currentPage
          query.search = entityList.orgCourses.filters.searchQuery
        } else {
          query.page = {
            offset: entityList.orgCourses.templates.currentPage * 50,
          }
          query.search = entityList.orgCourses.templates.filters.searchQuery
        }

        let response = await fetchWrapper.get(url + "?" + qs.stringify(query))

        if (response.ok) {
          let data = await response.json()

          if (!templates) {
            pages = data.pager.total_pages

            if (!data.rows.content) {
              // @ts-expect-error
              data.rows.forEach((row) => {
                row.image = row.field_category_image
              })

              content = content.concat(data.rows)
            }
          } else {
            pages = Math.ceil(Number(data.meta.count) / 50)
            let newTemplates = data.data.length
              ? data.data.filter(
                  // @ts-expect-error
                  (template) => !content.find((item) => item.id === template.id)
                )
              : []
            content = content.concat(newTemplates)
          }

          if (filters.organization !== "any") {
            content = sortBy(content, (course) => {
              let org
              if (!course.field_organization) {
                org = "MobileMind"
              } else {
                org = course.field_organization
              }
              return org
            })
          }

          if (templates) {
            dispatch({
              type: "entityList/getTemplates",
              payload: content,
              meta: {
                totalRecords: Number(data.meta.count),
                pages,
                fetched: true,
              },
            })
          } else {
            dispatch({
              type: "entityList/getOrgCourses",
              payload: content,
              meta: {
                totalRecords: Number(data.pager.total_items),
                pages,
                fetched,
              },
            })
          }
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function getCourses() {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { session } = getState()
    let orgId = session.group.uuid && session.group.uuid[0].value

    try {
      let pages = 1
      let i = 0

      let query: any = {
        sort: "name",
        filter: {
          draft: {
            group: {
              conjunction: "OR",
            },
          },
          "null-draft": {
            condition: {
              operator: "IS NULL",
              path: "field_draft",
              memberOf: "draft",
            },
          },
          "is-draft": {
            condition: {
              operator: "=",
              path: "field_draft",
              value: false,
              memberOf: "draft",
            },
          },
          archive: {
            group: {
              conjunction: "OR",
            },
          },
          "null-archive": {
            condition: {
              operator: "IS NULL",
              path: "field_archive",
              memberOf: "archive",
            },
          },
          "not-archived": {
            condition: {
              path: "field_archive",
              value: false,
              memberOf: "archive",
            },
          },
          source: {
            group: {
              conjunction: "OR",
            },
          },
          mobilemind: {
            condition: {
              operator: "IS NULL",
              path: "field_organization.id",
              memberOf: "source",
            },
          },
          organization: {
            condition: {
              path: "field_organization.id",
              value: orgId,
              memberOf: "source",
            },
          },
        },
        include: "field_mul,field_category",
      }

      while (i < pages) {
        query.page = {
          offset: i * 50,
        }

        let response = await fetchWrapper.get(
          "/api/course_entity/course_entity?" + qs.stringify(query)
        )
        if (response.ok) {
          let data = await response.json()
          pages = Math.ceil(data.meta.count / 50)

          // @ts-expect-error
          data.data.forEach((course) => {
            course.category = data.included.find(
              // @ts-expect-error
              (category) =>
                course.relationships.field_category.data &&
                category.id === course.relationships.field_category.data.id
            )
          })

          // @ts-ignore
          let filteredByPartnership = filterByPartners(
            session.group,
            session.subgroup,
            data.data
          )

          dispatch({
            type: "coursesSlice/getCourses",
            payload: filteredByPartnership,
            meta: {
              fetched: pages - 1 === i,
            },
          })
        }
        i++
      }
    } catch (err) {
      console.log(err)
    }
  }
}

type SaveCourseArgs = {
  activeCourse: CourseFormValues
  isDraft?: boolean
  isArchived?: boolean
  isCopy?: boolean
  fromTemplate?: boolean
  isSharing?: boolean
  shareSettings?: ModalCourseShareFormValues
  readyToPublish?: boolean
  isOwnCommunityCourse?: boolean
}

export function saveCourse(args: SaveCourseArgs) {
  const {
    activeCourse,
    isDraft,
    isCopy,
    fromTemplate,
    isArchived,
    isSharing,
    shareSettings,
    readyToPublish,
    isOwnCommunityCourse,
  } = args

  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { session, categories } = getState()
    const isDrafter =
      session.orgRoles &&
      ((!session.orgRoles.includes("organization-admin") &&
        session.orgRoles.includes("organization-drafter")) ||
        session.groupRoles.includes("group-drafter"))
    const { groupRoles } = session

    let categoryId: string | undefined
    if (activeCourse.topCategory && !activeCourse.subCategory) {
      let topCategoryId = activeCourse.topCategory
      let categoryFirstChild = categories.subCategories.find(
        (cat) => cat.relationships.parent.data[0].id === topCategoryId
      )
      if (categoryFirstChild) {
        categoryId = categoryFirstChild.id
      } else {
        categoryId = topCategoryId
      }
    } else if (activeCourse.subCategory) {
      categoryId = activeCourse.subCategory
    } else if (activeCourse.field_category) {
      categoryId = activeCourse.field_category.id
    }
    if (isCopy) {
      categoryId = activeCourse.field_category?.id
    }
    let copyName =
      activeCourse.name.length >= 40
        ? activeCourse.name.substring(0, 37) + "..."
        : activeCourse.name

    const attributes: Exclude<
      CourseEntityCourseEntity["data"]["attributes"],
      undefined
    > = {
      name: isCopy || fromTemplate ? "Copy of " + copyName : activeCourse.name,
      field_archive: isCopy ? false : isArchived,
      field_course_content: activeCourse.content,
      // @ts-expect-error
      field_additional_resources:
        activeCourse.resources?.replace("<p><br></p>", "") || "",
      field_mc_require_all: activeCourse.requireAllAnswers,
      field_course_estimated_time: activeCourse.estimatedTime,
      field_course_hint: activeCourse.hint,
      field_mc_shuffle: activeCourse.mc_shuffle,
      field_checkbox_options: activeCourse.checkboxOptions,
      field_course_objective: activeCourse.objective,
      field_level: activeCourse.level,
      field_ques: activeCourse.question,
      field_reviewer: activeCourse.reviewerInstructions,
      field_teacher_instructions: activeCourse.learnerInstructions,
      field_submission_type: activeCourse.submissionType,
      field_video_link: activeCourse.field_video_link,
      field_mobile_friendly: activeCourse.mobileFriendly,
      field_automatic_review:
        activeCourse.submissionType === "Text" &&
        activeCourse.reviewMethod !== "manual",
      field_creator_org_name: fromTemplate
        ? activeCourse.creatorOrgName
        : undefined,
      field_created_with_genie: fromTemplate
        ? false
        : activeCourse.field_created_with_genie,
    }

    if (!isSharing && !isOwnCommunityCourse) {
      attributes.field_ex_from_lib = activeCourse.excludeFromExplore
    }

    if (isDraft) {
      attributes.field_ready_to_publish = readyToPublish
    }
    if (fromTemplate) {
      attributes.field_ready_to_publish = true
    }

    if (!isSharing) {
      // @ts-expect-error
      attributes.field_release_date = moment(activeCourse.releaseDate).format(
        "YYYY-MM-DD"
      )

      // @ts-expect-error
      attributes.field_support_email = activeCourse.supportEmail
    }
    if (shareSettings) {
      if (isSharing) {
        attributes.field_credit_organization = shareSettings.credit
          ? true
          : false
      } else {
        let community: Array<"mobilemind" | "community"> = []
        // @ts-expect-error
        if (shareSettings.isMM) {
          community.push("mobilemind")
        }
        if (shareSettings.isCommunity) {
          community.push("community")
        }

        attributes.field_mm_community = community
        attributes.field_agreed_to_terms_of_service = true
        attributes.field_credit_organization = shareSettings.credit
          ? true
          : false
      }
    }

    if (!isDrafter) {
      if (isCopy || isDraft) {
        attributes.field_draft = true
      }
      if (!isDraft) {
        attributes.field_draft = false
      }
    }

    let relationships: Exclude<
      CourseEntityCourseEntity["data"]["relationships"],
      undefined
    > = {}

    // Only set content lock fields if not a template
    if (!isSharing) {
      // @ts-expect-error
      attributes.field_editor_lock_time = null

      relationships.field_current_editor = {
        // @ts-expect-error
        data: null,
      }
    }

    if (!fromTemplate && !isSharing) {
      relationships.field_tags = {
        data: [],
      }

      let i = 0
      while (i < activeCourse.tags.length) {
        if (activeCourse.tags[i].uuid) {
          relationships.field_tags?.data?.push({
            type: "taxonomy_term--tags",
            id: activeCourse.tags[i].uuid,
          })
        } else {
          let newTag: Omit<TaxonomyTermCategory, "id"> = {
            data: {
              type: "taxonomy_term--category",
              attributes: {
                name: activeCourse.tags[i].text,
              },
              // We'll set the org OR the partner depending on the user
              relationships: {},
            },
          }

          if (session.isPartner) {
            newTag.data.relationships.field_partner = {
              data: {
                type: "group--partner",
                id: session.group.uuid[0].value,
              },
            }
          } else {
            newTag.data.relationships.field_organization = {
              data: {
                type: "group--organization",
                id: session.group.uuid[0].value,
              },
            }
          }

          let tagRequest = await fetchWrapper.post(
            "/api/taxonomy_term/tags",
            session.token,
            JSON.stringify(newTag)
          )
          if (tagRequest.ok) {
            let tagData = await tagRequest.json()
            dispatch({
              type: "tags/addTag",
              payload: tagData.data,
            })
            relationships.field_tags?.data?.push({
              type: "taxonomy_term--tags",
              id: tagData.data.id,
            })
          }
        }
        i++
      }
    }

    // Add the creator org if we're sharing as a template
    if (isSharing) {
      // @ts-expect-error
      relationships.field_creator_org = {
        data: {
          type: "group--organization",
          id: session.group.uuid[0].value,
        },
      }

      attributes.field_creator_org_name = session.group.label[0].value
    }

    // Only include the org if they are not a MM administrator and we're not sharing
    if (!session.roles.find((role) => role.target_id === "administrator")) {
      if (session.group.type && session.group.type[0].target_id === "partner") {
        relationships.field_partner = {
          // @ts-expect-error
          data: [
            {
              type: "group--partner",
              id: session.group.uuid[0].value,
            },
          ],
        }
      } else {
        relationships.field_organization = {
          // @ts-expect-error
          data: [
            {
              type: "group--organization",
              id: session.group.uuid[0].value,
            },
          ],
        }
      }
    }

    // Set subgroup owner if user is group level
    const isGroupAdmin =
      !session.orgRoles.includes("organization-admin") &&
      !session.orgRoles.includes("organization-creator") &&
      !session.orgRoles.includes("organization-drafter") &&
      (groupRoles.includes("group-admin") ||
        groupRoles.includes("group-creator") ||
        groupRoles.includes("group-drafter"))

    // Don't set this for copies

    if (!isCopy) {
      if (
        (activeCourse.subGroupOwner && activeCourse.subGroupOwner !== "org") ||
        isGroupAdmin
      ) {
        relationships.field_subgroup_owner = {
          data: {
            type: "group--group",
            id: activeCourse.subGroupOwner
              ? activeCourse.subGroupOwner
              : session.subgroups.data[0].id,
          },
        }
      } else {
        relationships.field_subgroup_owner = {
          // @ts-expect-error
          data: null,
        }
      }
    }

    if (categoryId) {
      relationships.field_category = {
        data: {
          type: "taxonomy_term--category",
          id:
            isSharing &&
            shareSettings &&
            shareSettings.needsReplacementCategory &&
            shareSettings.category
              ? shareSettings.category.id
              : categoryId,
        },
      }
    }

    if (fromTemplate && activeCourse.id && !isSharing) {
      relationships.field_source_template = {
        data: {
          type: "course_template--course_template",
          id: activeCourse.id,
        },
      }
    }

    // Course reviewers
    if (!isSharing) {
      relationships.field_reviewer_group = {
        data: activeCourse.reviewerGroups.map((group) => {
          return {
            type: "group--rev_group",
            id: group.id,
          }
        }),
      }

      if (activeCourse.reviewers.length) {
        relationships.field_reviewers = {
          data: activeCourse.reviewers.map((reviewer) => {
            return {
              type: "user--user",
              id: reviewer.id,
            }
          }),
        }
      } else {
        relationships.field_reviewers = {
          // @ts-expect-error
          data: null,
        }
      }
    }

    if (isCopy && activeCourse.id && !fromTemplate && !isSharing) {
      relationships.field_source_clone = {
        data: {
          type: "course_entity--course_entity",
          id: activeCourse.id,
        },
      }
    }

    if (!isSharing) {
      relationships.field_related_courses = { data: [] }
      relationships.field_related_courses.data =
        activeCourse.relatedCourses.map((course) => {
          return { type: "course_entity--course_entity", id: course.uuid }
        })
    }

    // Don't set subgroup or job title if this is a copy
    if (!isSharing && (!isCopy || isGroupAdmin)) {
      if (activeCourse.subGroups.length) {
        relationships.field_subgroup = {
          data: [],
        }

        activeCourse.subGroups.forEach((group) => {
          if (
            relationships.field_subgroup &&
            relationships.field_subgroup.data
          ) {
            relationships.field_subgroup.data.push({
              type: "group--group",
              id: group.id,
            })
          }
        })
      } else if (isGroupAdmin && session.subgroups.data.length === 1) {
        relationships.field_subgroup = {
          data: [
            {
              type: "group--group",
              id: session.subgroups.data[0].id,
            },
          ],
        }
      } else {
        relationships.field_subgroup = {
          // @ts-expect-error
          data: null,
        }
      }

      if (activeCourse.jobTitles.length && !isSharing) {
        relationships.field_job_title = {
          data: [],
        }

        activeCourse.jobTitles.forEach((jobTitle) => {
          relationships.field_job_title?.data?.push({
            type: "taxonomy_term--job_titles",
            id: jobTitle.id,
          })
        })
      } else {
        relationships.field_job_title = {
          // @ts-expect-error
          data: null,
        }
      }
    }

    const body:
      | Omit<Exclude<CourseEntityCourseEntity, undefined>, "id">
      | Omit<Exclude<CourseTemplateCourseTemplate, undefined>, "id"> = {
      data: {
        id: activeCourse.id && !isCopy ? activeCourse.id : undefined,
        type: !isSharing
          ? "course_entity--course_entity"
          : "course_template--course_template",
        attributes,
        relationships,
      },
    }

    try {
      let response

      if (activeCourse.id && !isCopy) {
        if (activeCourse.isTemplate) {
          response = await fetchWrapper.patch(
            "/api/course_template/course_template/" + activeCourse.id,
            session.token,
            JSON.stringify(body)
          )
        } else if (!isSharing) {
          response = await fetchWrapper.patch(
            "/api/course_entity/course_entity/" + activeCourse.id,
            session.token,
            JSON.stringify(body)
          )
        }
      }

      if ((!activeCourse.id || isCopy) && !isSharing) {
        response = await fetchWrapper.post(
          "/api/course_entity/course_entity",
          session.token,
          JSON.stringify(body)
        )
      }

      if (isSharing && !activeCourse.isTemplate) {
        response = await fetchWrapper.post(
          "/api/course_template/course_template/",
          session.token,
          JSON.stringify(body)
        )
      }

      if (response.ok) {
        let data = await response.json()
        let createdEntity = data.data

        const genieActive = selectCourseGenieActive(getState())
        if (genieActive) {
          dispatch(finishCourseGenie(createdEntity.id))
        }

        // Refetch org success checklist
        dispatch(getOrgSuccessChecklist())

        createdEntity.relationships.field_category = categories.data.find(
          (cat) => cat.id === categoryId
        )

        // If it's a Multiple Choice course, construct our subrequests payload
        if (activeCourse.submissionType === "Multiple Choice") {
          let multipleChoiceRequest: any[] = [
            {
              requestId: "paragraph_type",
              action: "view",
              uri: "/api/paragraphs_type/paragraphs_type?filter[label]=Multiple Choice",
              headers: { Accept: "application/vnd.api+json" },
            },
          ]

          // This will keep track of the paragraph requests we need to wait for before updating the course
          let courseWaitFor: string[] = []

          // This will be the value for the update course.relationships.field_mul
          let field_mul: object[] = []

          activeCourse.answers.forEach((answer, index) => {
            courseWaitFor.push("paragraph-" + index)
            field_mul.push({
              type: "paragraph--multiple_choice",
              id: `{{paragraph-${index}.body@$.data.id}}`,
              meta: {
                target_revision_id: `{{paragraph-${index}.body@$.data.attributes.drupal_internal__revision_id}}`,
              },
            })

            /**
             * The subrequests module expects strings for everything, so serialize the body of each request
             */

            // For each answer, create a paragraph we will later attach to the course (via field_mul), or update the existing one if changes were made
            if (answer.id && !fromTemplate && !isCopy && !isSharing) {
              multipleChoiceRequest.push({
                action: "update",
                requestId: "paragraph-" + index,
                uri: "/api/paragraph/multiple_choice/" + answer.id,
                headers: { "Content-Type": "application/vnd.api+json" },
                waitFor: ["paragraph_type"],
                body: JSON.stringify({
                  data: {
                    type: "paragraph--multiple_choice",
                    id: answer.id,
                    attributes: {
                      field_mc_answer: answer.attributes.field_mc_answer,
                      field_mc_answer_type:
                        answer.attributes.field_mc_answer_type,
                    },
                  },
                }),
              })
            } else {
              multipleChoiceRequest.push({
                action: "create",
                requestId: "paragraph-" + index,
                uri: "/api/paragraph/multiple_choice/",
                headers: { "Content-Type": "application/vnd.api+json" },
                waitFor: ["paragraph_type"],
                body: JSON.stringify({
                  data: {
                    type: "paragraph--multiple_choice",
                    attributes: {
                      parent_id: createdEntity.attributes.drupal_internal__id,
                      parent_type: "course_entity",
                      parent_field_name: "field_mul",
                      field_mc_answer: answer.attributes.field_mc_answer,
                      field_mc_answer_type:
                        answer.attributes.field_mc_answer_type,
                    },
                    relationships: {
                      paragraph_type: {
                        data: {
                          type: "paragraphs_type--paragraphs_type",
                          id: "{{paragraph_type.body@$.data[0].id}}",
                        },
                      },
                    },
                  },
                }),
              })
            }
          })

          // Push a request object to the array that will update the course and wait until all paragraphs are finished being created/updated
          if (isSharing) {
            multipleChoiceRequest.push({
              action: "update",
              requestId: "course_update",
              body: JSON.stringify({
                data: {
                  type: "course_template--course_template",
                  id: createdEntity.id,
                  relationships: {
                    field_mul: {
                      data: field_mul,
                    },
                  },
                },
              }),
              uri: "/api/course_template/course_template/" + createdEntity.id,
              headers: { "Content-Type": "application/vnd.api+json" },
              waitFor: courseWaitFor,
            })
          } else {
            multipleChoiceRequest.push({
              action: "update",
              requestId: "course_update",
              body: JSON.stringify({
                data: {
                  type: "course_entity--course_entity",
                  id: createdEntity.id,
                  relationships: {
                    field_mul: {
                      data: field_mul,
                    },
                  },
                },
              }),
              uri: "/api/course_entity/course_entity/" + createdEntity.id,
              headers: { "Content-Type": "application/vnd.api+json" },
              waitFor: courseWaitFor,
            })
          }
          await fetchWrapper.post(
            "/subrequests",
            session.token,
            JSON.stringify(multipleChoiceRequest)
          )
        }

        // If it's got a quiz...start with the quiz entity itself
        if (activeCourse.submissionType === "Quiz") {
          let passRate =
            activeCourse.quizPassThreshold < 100
              ? Number("0." + activeCourse.quizPassThreshold)
              : "1.00"

          // If we haven't created the quiz entity yet, do that first
          let quizBody: Omit<Exclude<QuizEntityCourseQuiz, undefined>, "id"> = {
            data: {
              type: "quiz_entity--course_quiz",
              attributes: {
                name:
                  activeCourse.name.length >= 50
                    ? activeCourse.name.substring(0, 40) + "... Quiz"
                    : activeCourse.name.substring(0, 40) + " Quiz",
                field_pass_rate: passRate,
                field_show_correct_answers: activeCourse.showCorrectAnswers,
                field_show_missed_questions: activeCourse.showMissedQuestions,
              },
            },
          }

          let quizRequest
          let shouldPatch = activeCourse.quizId && !isCopy && !isSharing
          if (isOwnCommunityCourse) {
            shouldPatch = true
          }

          if (shouldPatch) {
            quizBody.data.id = activeCourse.quizId
            quizRequest = await fetchWrapper.patch(
              "/api/quiz_entity/course_quiz/" + activeCourse.quizId,
              session.token,
              JSON.stringify(quizBody)
            )
          } else {
            quizRequest = await fetchWrapper.post(
              "/api/quiz_entity/course_quiz/",
              session.token,
              JSON.stringify(quizBody)
            )
          }

          let createdQuiz = await quizRequest.json()

          // Patch the course so it references quiz
          let quizUpdate = {
            data: {
              type: isSharing
                ? "course_template--course_template"
                : "course_entity--course_entity",
              id: createdEntity.id,
              relationships: {
                field_quiz: {
                  data: {
                    type: "quiz_entity--course_quiz",
                    id: createdQuiz.data.id,
                  },
                },
              },
            },
          }

          // Stick the quiz on the course -- or template
          if (isSharing) {
            await fetchWrapper.patch(
              "/api/course_template/course_template/" + createdEntity.id,
              session.token,
              JSON.stringify(quizUpdate)
            )
          } else {
            await fetchWrapper.patch(
              "/api/course_entity/course_entity/" + createdEntity.id,
              session.token,
              JSON.stringify(quizUpdate)
            )
          }

          // SO...we've gotta break these questions up into chunks of 5, post them on the quiz, then get the quiz...
          // ... then get the whole quiz again and build an array of all question IDs
          let i = 0
          let mcQuestions: Array<{
            id: string
            type: "paragraph--mc_question"
          }> = []
          let questionChunks = chunk(activeCourse.quizQuestions, 5)
          const shouldPost = !isOwnCommunityCourse && (isCopy || isSharing)
          while (i < questionChunks.length) {
            let questionsRequest = generateQuizQuestionsRequest(
              questionChunks[i],
              createdQuiz,
              shouldPost
            )
            await fetchWrapper.post(
              "/subrequests",
              session.token,
              JSON.stringify(questionsRequest)
            )

            let updatedQuiz = await fetchWrapper.get(
              "/api/quiz_entity/course_quiz/" + createdQuiz.data.id
            )
            let updatedResponse: Exclude<QuizEntityCourseQuiz, undefined> =
              await updatedQuiz.json()

            updatedResponse.data.relationships!.field_mc_questions!.data!.forEach(
              (question) => {
                mcQuestions.push(question)
              }
            )

            i++
          }

          const update: Exclude<QuizEntityCourseQuiz, undefined> = {
            data: {
              id: createdQuiz.data.id,
              type: "quiz_entity--course_quiz",
              relationships: {
                field_mc_questions: {
                  data: mcQuestions,
                },
              },
            },
          }

          // Finally put all those question IDs on the quiz
          await fetchWrapper.patch(
            "/api/quiz_entity/course_quiz/" + createdQuiz.data.id,
            session.token,
            JSON.stringify(update)
          )
        }

        if (
          activeCourse.submissionType === "Text" &&
          activeCourse.reviewMethod !== "manual"
        ) {
          let fieldAnswer =
            activeCourse.reviewMethod === "include"
              ? activeCourse.phrases.join()
              : activeCourse.exactMatch

          let answerKey: Omit<
            Exclude<AnswerKeyCourseAnswers, undefined>,
            "id"
          > = {
            data: {
              type: "answer_key--course_answers",
              attributes: {
                field_answer: fieldAnswer,
                field_operator:
                  activeCourse.includeOption === "any" ? "or" : "and",
                field_exact:
                  activeCourse.reviewMethod === "include" ? false : true,
              },
            },
          }

          if (!isSharing) {
            answerKey.data.relationships = {
              field_course: {
                data: {
                  type: "course_entity--course_entity",
                  id: createdEntity.id,
                },
              },
            }
          } else {
            answerKey.data.relationships = {
              field_template: {
                data: {
                  type: "course_template--course_template",
                  id: createdEntity.id,
                },
              },
            }
          }

          if (
            activeCourse.answerKeyId &&
            !isCopy &&
            (!isSharing || isOwnCommunityCourse)
          ) {
            answerKey.data.id = activeCourse.answerKeyId
            await fetchWrapper.patch(
              "/api/answer_key/course_answers/" + activeCourse.answerKeyId,
              session.token,
              JSON.stringify(answerKey)
            )
          } else if (!activeCourse.answerKeyId || isCopy || isSharing) {
            await fetchWrapper.post(
              "/api/answer_key/course_answers",
              session.token,
              JSON.stringify(answerKey)
            )
          }
        }

        if (activeCourse.id && !isCopy) {
          dispatch({
            type: "entityList/updateCourse",
            payload: createdEntity,
          })
        } else {
          dispatch({
            type: "entityList/addCreatedCourse",
            payload: createdEntity,
          })
        }

        return data.data
      } else {
        dispatch({
          type: "snackbar/openSnackbar",
          payload: {
            message:
              "There was a problem saving this course. Try again in a few minutes.",
          },
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function deleteCourse(courseId: string) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { session } = getState()
    try {
      let response = await fetchWrapper.remove(
        "/api/course_entity/course_entity/" + courseId,
        session.token
      )
      if (response.ok) {
        dispatch({
          type: "entityList/deleteCourse",
          payload: courseId,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function publishCourseFromList(course: CourseEntityCourseEntity) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { session } = getState()
    try {
      let body = {
        data: {
          id: course.uuid,
          type: "course--entity_course--entity",
          attributes: {
            field_draft: false,
          },
        },
      }

      let response = await fetchWrapper.patch(
        "/api/course_entity/course_entity/" + course.uuid,
        session.token,
        JSON.stringify(body)
      )
      if (response.ok) {
        dispatch({
          type: "entityList/publishCourse",
          payload: { uuid: course.uuid },
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function removeReviewerFromCourse(course: CourseEntityCourseEntity) {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: "reviewSlice/removeCourse",
        payload: { course },
      })

      await fetchWrapper.get(
        "/api/course_entity/remove-reviewer/" + Number(course.id)
      )
    } catch (err) {
      console.log(err)
    }
  }
}
