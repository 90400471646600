import React from "react"
import { Box, Card, TextField, Stack } from "@mui/material"
import { useFormikContext, Field } from "formik"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { formats, modules } from "../../../constants/reactQuillConfig"
import UserInitial from "@mobilemind/common/src/components/UserInitial"
import DeleteButton from "components/DeleteButton"
import DragHandleIcon from "@mui/icons-material/DragHandle"
import { PreApprovalFormEntity } from "../types"
import ButtonSmall from "@mobilemind/common/src/components/ButtonSmall"
import { deletePreApprovalStep } from "store/reducers/preApprovalForms"
import { useAppDispatch } from "store/hooks"

type ApprovalStepProps = {
  index: number
  setEditingStepIndex: () => void
  setUserSelectOpen: () => void
}

export const ApprovalStep = (props: ApprovalStepProps) => {
  const { index, setEditingStepIndex, setUserSelectOpen } = props
  const { setFieldValue, values } = useFormikContext<PreApprovalFormEntity>()
  const dispatch = useAppDispatch()
  const step = values.approvalSteps && values.approvalSteps[index]

  if (step) {
    const { label, field_approver_instructions, field_turn_time } =
      step.attributes

    return (
      <Card sx={{ marginBottom: 3, paddingTop: 2 }}>
        <h3 style={{ ...styles.subheading }}>Approval Step {index + 1}</h3>

        <Box
          key={index}
          style={{
            borderBottom: "1px solid grey.500",
            display: "flex",
            alignItems: "center",
          }}
        >
          <DragHandleIcon
            sx={{
              color: "grey.500",
              gridRowStart: 2,
              position: "relative",
              top: 2,
              marginLeft: 2,
            }}
          />

          <Field
            style={{
              marginBottom: 10,
              marginLeft: 10,
              marginRight: 20,
            }}
            as={TextField}
            label={"Step " + (index + 1) + " Name"}
            value={label ?? "Approval Step " + (index + 1)}
            onChange={(event: any) => {
              setFieldValue(
                `approvalSteps.${index}.attributes.label`,
                event.target.value
              )
            }}
            id={`approvalSteps.${index}.attributes.label`}
            name={`approvalSteps.${index}.attributes.label`}
          />
        </Box>
        <Box
          sx={{
            marginTop: 1,
            padding: 3,
            paddingTop: 0,
          }}
        >
          <Stack
            direction="row"
            sx={{
              marginBottom: 2,
              alignItems: "center",
            }}
          >
            <h4 style={{ ...styles.h4 }}>Approvers</h4>

            <ButtonSmall
              onClick={() => {
                setEditingStepIndex()
                setUserSelectOpen()
              }}
            >
              <span className="icon pre-approval-blue" />
              <span
                style={{
                  textTransform: "uppercase",
                }}
              >
                Add Approvers
              </span>
            </ButtonSmall>
          </Stack>

          {!step.reviewers?.length && !step.reviewerGroups?.length && (
            <Box
              sx={{
                display: "inline-flex",
                borderRadius: 2,
                backgroundColor: "grey.200",
                padding: 1,
                paddingInline: 2,
                marginBottom: 3,
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                }}
              >
                Add at least one approver or reviewer group.
              </span>
            </Box>
          )}

          {(step.reviewers?.length > 0 || step.reviewerGroups?.length > 0) && (
            <Stack
              direction="row"
              sx={{
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {step.reviewers.map((reviewer) => {
                const firstName =
                  reviewer.attributes?.field_first_name ??
                  reviewer.field_first_name
                const lastName =
                  reviewer.attributes?.field_last_name ??
                  reviewer.field_last_name

                return (
                  <Stack
                    key={reviewer.id}
                    direction="row"
                    sx={{
                      alignItems: "center",
                      marginBottom: 4,
                      marginRight: 3,
                    }}
                  >
                    <UserInitial
                      initials={firstName.charAt(0) + lastName.charAt(0)}
                      containerStyle={{
                        marginRight: 8,
                      }}
                      fontSize={16}
                      size={40}
                    />
                    <strong>
                      {firstName} {lastName}
                    </strong>

                    <DeleteButton
                      onClick={() => {
                        // Go through the steps and find the one we're in
                        if (values.approvalSteps) {
                          const existing = [...values.approvalSteps]

                          // Remove the reviewer from the step
                          existing[index].reviewers = existing[
                            index
                          ].reviewers.filter(
                            (existingReviewer) =>
                              existingReviewer.id !== reviewer.id
                          )
                          // Set approval steps
                          setFieldValue("approvalSteps", existing)
                        }
                      }}
                    />
                  </Stack>
                )
              })}

              {step.reviewerGroups.map((reviewerGroup) => {
                return (
                  <Stack
                    key={reviewerGroup.id}
                    direction="row"
                    sx={{
                      alignItems: "center",
                      marginBottom: 4,
                      marginRight: 3,
                    }}
                  >
                    <span
                      style={{
                        width: 35,
                        height: 35,
                        marginRight: 8,
                      }}
                      className="icon reviewer-group"
                    />

                    <strong>{reviewerGroup.attributes.label}</strong>

                    <DeleteButton
                      onClick={() => {
                        // Go through the steps and find the one we're in
                        if (values.approvalSteps) {
                          const existing = [...values.approvalSteps]

                          // Remove the reviewer from the step
                          existing[index].reviewerGroups = existing[
                            index
                          ].reviewerGroups.filter(
                            (existingReviewerGroup) =>
                              existingReviewerGroup.id !== reviewerGroup.id
                          )
                          // Set approval steps
                          setFieldValue("approvalSteps", existing)
                        }
                      }}
                    />
                  </Stack>
                )
              })}
            </Stack>
          )}
          <h4 style={{ ...styles.h4 }}>Approval Instructions</h4>

          <ReactQuill
            className="approver-instructions-quill"
            theme={"snow"}
            onChange={(event) => {
              setFieldValue(
                `approvalSteps.${index}.attributes.field_approver_instructions`,
                event
              )
            }}
            //@ts-ignore
            value={field_approver_instructions ?? ""}
            modules={modules}
            formats={formats}
            placeholder={"Approver Instructions"}
          />

          <h4 style={{ ...styles.h4, marginTop: 10 }}>Turnaround Time</h4>

          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              marginTop: 3,
            }}
          >
            <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
              <Field
                style={{
                  marginBottom: 0,
                  marginRight: 15,
                  maxWidth: 70,
                }}
                type="number"
                as={TextField}
                value={field_turn_time}
                onChange={(event: any) => {
                  setFieldValue(
                    `approvalSteps.${index}.attributes.field_turn_time`,
                    event.target.value
                  )
                }}
                id={`approvalSteps.${index}.attributes.field_turn_time`}
                name={`approvalSteps.${index}.attributes.field_turn_time`}
              />

              <span>Day{field_turn_time > 1 && <>s</>}</span>
            </Box>

            {values?.approvalSteps?.length &&
              values?.approvalSteps?.length > 1 && (
                <ButtonSmall
                  onClick={() => {
                    if (values.approvalSteps) {
                      const existing = [...values.approvalSteps]
                      setFieldValue(
                        "approvalSteps",
                        existing.filter((_, i) => i !== index)
                      )
                    }

                    if (step.id) {
                      // Delete the step from the server
                      dispatch(
                        deletePreApprovalStep({
                          stepId: step.id,
                          values,
                        })
                      )
                    }
                  }}
                >
                  <span className="icon trash" />
                  <span style={{ color: "#DA3737" }}>Delete</span>
                </ButtonSmall>
              )}
          </Stack>
        </Box>
      </Card>
    )
  }
  return null
}

const styles = {
  subheading: {
    fontSize: 18,
    marginLeft: 20,
    marginTop: 10,
    color: "black",
    textTransform: "none" as "none",
  },
  h4: {
    opacity: 0.5,
    marginTop: 0,
    marginBottom: 0,
    flex: 1,
  },
}
